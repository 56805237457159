import _ from 'lodash'
import CreatableSelect from 'react-select/creatable'
import AsyncSelect from 'react-select/async'
import { AsyncPaginate } from 'react-select-async-paginate'
import WindowedSelect, {
  createFilter,
  GroupBase,
  SelectComponentsConfig,
  StylesConfig,
} from 'react-windowed-select'

// constants
import { NO_DATA_PLACEHOLDER } from 'constants/common'

import type { Options, Payload } from 'types/common'
import type { MultiSelectProp, SelectOption } from './types'

import { DropdownIndicator, OptionLabel } from './components'

/**
 * Only load a portion of the options to improve performance.
 */
export const loadAsyncPaginateSelectOptions = ({
  options,
  search,
  page = 1,
  optionsPerPage = 5,
}: {
  options: Options
  page?: number
  search?: string
  optionsPerPage?: number
}) => {
  let filteredOptions: Options
  if (search) {
    const searchLower = search.toLowerCase()
    filteredOptions = _.filter(options, ({ label }) =>
      label.toLowerCase().includes(searchLower)
    )
  } else {
    filteredOptions = options
  }

  const hasMore = Math.ceil(filteredOptions.length / optionsPerPage) > page

  const slicedOptions = _.slice(
    filteredOptions,
    (page - 1) * optionsPerPage,
    page * optionsPerPage
  )

  return {
    options: slicedOptions,
    hasMore,
  }
}

/**
 *  In case the preselected options are not included in the paginated options,
 *  and remove the duplicated options
 */
export const getAsyncPaginateNewOptions = ({
  page,
  options,
  selectedOptions,
}: {
  page: number
  options: Options
  selectedOptions: Options
}) => {
  if (_.isEmpty(_.compact(selectedOptions))) return options

  return page === 1
    ? _([...selectedOptions, ...options])
        .compact()
        .uniqBy('value')
        .value()
    : _.differenceBy(options, selectedOptions, 'value')
}

const getNoOptionsMessage = ({
  value,
  maxOptionsLength = Infinity,
  noDataMessage = NO_DATA_PLACEHOLDER,
}: {
  value: Options | null | undefined
  maxOptionsLength?: number
  noDataMessage?: string
}) => {
  if (_.isNil(value)) return false
  return value.length === maxOptionsLength
    ? "You've reached the max number of options."
    : noDataMessage
}

const customFilterOption = createFilter({
  // Default is `${option.label} ${option.value}`,
  stringify: option => `${option.label}`,
  // Setting ignoreAccents to false can significantly improve performance, especially when dealing with large datasets.
  // https://johnnyreilly.com/react-select-with-less-typing-lag
  ignoreAccents: false,
})

export const CLASS_NAME_PREFIX = 'select'

export const getMultiSelectCommonProps = ({
  isMulti = true,
  className,
  value,
  maxOptionsLength = Infinity,
  noDataMessage = NO_DATA_PLACEHOLDER,
  customStyles,
  components,
  formatOptionLabel,
}: {
  isMulti: boolean
  className?: string
  value: Options | null | undefined
  maxOptionsLength?: number
  noDataMessage?: string
  customStyles: StylesConfig
  components?: SelectComponentsConfig<
    SelectOption,
    boolean,
    GroupBase<SelectOption>
  >
  formatOptionLabel: MultiSelectProp['formatOptionLabel']
}): Payload => ({
  styles: customStyles,
  closeMenuOnSelect: !isMulti,
  className: `customSelect ${className}`,
  classNamePrefix: CLASS_NAME_PREFIX,
  isMulti,
  noOptionsMessage: () =>
    getNoOptionsMessage({
      value,
      maxOptionsLength,
      noDataMessage,
    }),
  formatCreateLabel: (v: string) => `+ Add "${v}"`,
  filterOption: customFilterOption,
  formatOptionLabel: formatOptionLabel || OptionLabel,
  components: { ...components, DropdownIndicator },
})

export const getSelectComponent = ({
  creatable,
  isAsync,
  isAsyncPaginate,
}: Partial<{
  creatable: boolean
  isAsync: boolean
  isAsyncPaginate: boolean
}>) => {
  if (creatable) return CreatableSelect

  if (isAsync) return AsyncSelect

  if (isAsyncPaginate) return AsyncPaginate

  return WindowedSelect
}

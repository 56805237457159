import _ from 'lodash'
import { useMemo } from 'react'

// utils
import { useCurrentUser, useRecoilLoadableResult } from 'hooks'
import { assetSitesOptionsState } from 'app/MissionControlMethaneSolution/recoilStore/assetTypeStore'

// components
import FilterDropdown from 'components/common/List/FilterBuilder/FilterDropdown'

import type { Options } from 'types/common'

const AssetSitesFilterAsyncDropdown = ({
  value: selectedAssetOptions,
  ...rest
}: {
  value: Options
  title: string
}) => {
  const { sessionUserGroup } = useCurrentUser()

  const { data: assetSiteOptions, loading: isAssetSitesListLoading } =
    useRecoilLoadableResult(assetSitesOptionsState)

  const assetSiteOptionsBySessionGroup = useMemo(() => {
    return sessionUserGroup
      ? _.filter(assetSiteOptions, { group: sessionUserGroup })
      : assetSiteOptions
  }, [assetSiteOptions, sessionUserGroup])

  return (
    <FilterDropdown
      {..._.omit(rest, ['filters'])}
      isMulti
      options={assetSiteOptionsBySessionGroup}
      value={selectedAssetOptions}
      isLoading={isAssetSitesListLoading}
    />
  )
}

export default AssetSitesFilterAsyncDropdown
